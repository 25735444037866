import { useStaticQuery, graphql } from 'gatsby';

const useHeelkickPartnersPage = () => {
    const { sanityPageHeelkickPartners: PageHeelkickPartners } = useStaticQuery(graphql`
        query {
            sanityPageHeelkickPartners {
                bannerImage {
                    image {
                        ...ImageWithPreview
                    }
                    alt
                }
                title {
                    en
                    se
                }
                description {
                    se {
                        _rawContent
                    }
                    en {
                        _rawContent
                    }
                }
                heelkickPartners {
                    title
                    url
                    logo {
                        ...ImageWithPreview
                    }
                }
            }
        }
    `);

    return PageHeelkickPartners;
};

export default useHeelkickPartnersPage;
